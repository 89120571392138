import React, { useContext, useState, useRef, useEffect } from "react";
import { LogoutService, ImgUrl } from "../services";
import GView from "../Elements/GView";
import styled from "styled-components";
import ChangePassword from "../forms/ChangePassword";
import EditProfile from "../forms/editProfile";
import ForgotPassword from "../forms/forgotPassword";
import SavedRestaurants from "../Components/savedRestaurants";
import SignUp from "../forms/signup";
import SignIn from "../forms/login";
import { Link } from "react-router-dom";
import GAvtar from "../Elements/GAvtar";
import Modal from "react-modal";
import GConfirmDialog from "../Elements/gConfirmDialog";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import { ReactComponent as UserIcon } from "../assets/images/user.svg";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import ChangeLanguage from "../Localisation/ChangeLanguage";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";
import { Popper, Grow, ClickAwayListener } from "@mui/material";

const Header = styled.header`
  background: #fff;
  box-shadow: -1px -1px 10px #bbbbbb5c;
  box-sizing: border-box;
  // padding: 6px 12px;
  z-index: 9;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  display: block;

  a {
    padding: 0 10px;
    text-decoration: none;
    color: #333;
    display: flex;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
  }
`;

const LinkDropdown = styled.div`
  position: absolute;
  background: #fff;
  right: 15px;
  top: 20px;
  min-width: 200px;
  box-shadow: 0 0 13px #c1bebe8f;
  border-top: 3px solid ${({ theme }) => theme.body};
  padding: 10px 0;

  &::before {
    content: "";
    position: absolute;
    background: #fff;
    border-color: ${({ theme }) => theme.body};
    border: 1px solid ${({ theme }) => theme.body};
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-width: 3px;
    padding: 7px;
    margin-left: -10px;
    transform: rotate(45deg);
    top: -10px;
    right: 30px;
    z-index: 1;
  }
  ul {
    display: block;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    li {
      a {
        padding: 10px 20px;
        text-decoration: none;
        color: #222;
        cursor: pointer;
        display: block;
        text-align: left;

        &:hover {
          background: #f4f5f9;
          color: #000;
        }
      }
    }
  }
`;

const HeaderView = () => {
  const [strings] = useContext(StringsContext);
  const { width } = useWindowSize();
  const { settings } = useContext(SettingsContext);
  const [wishlistVisible, setWishlistVisible] = useState(false);
  const [cpvisible, setCpvisible] = React.useState(false);
  const [epvisible, setEpvisible] = React.useState(false);
  const [fpvisible, setFpvisible] = React.useState(false);
  const [signupvisible, setSignupvisible] = React.useState(false);
  const [signinvisible, setSigninvisible] = useState(false);
  const [localisation, setLocalisation] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const handleClose = (event) => {
    setOpen(false);
    setPlacement();
  };

  const customStyles = {
    content: {
      width: "400px",
    },
  };

  useEffect(() => {
    console.log(settings);
    if (settings) {
      let l = settings?.localisation
        ? JSON.parse(settings?.localisation)
        : settings?.localisation;
      setLocalisation(l);
    }
  }, [settings]);

  const handleLogout = () => {
    LogoutService("c/logout")
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          console.log(response);
          localStorage.setItem("uwAccessToken", "");
          localStorage.setItem("uwRefreshToken", "");
          localStorage.setItem("uwExpiresIn", 0);
          window.location = "/";
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Header style={{ minHeight: "40px" }}>
      <div
        className="main-content"
        style={{ marginTop: "0", marginBottom: "0", height: "100%" }}
      >
        <GView
          style={{
            display: "flex",
            padding: "0",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Link
            to="/"
            style={{
              display: "inline-flex",
              height: "100%",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            {settings?.web?.web_logo ? (
              <img
                src={ImgUrl("web") + "/" + settings?.web?.web_logo}
                className="header-logo"
                alt={settings?.project_name}
                // onError={addDefaultLogo}
                style={{ maxWidth: "180px", width: "80%", maxHeight: "60px" }}
              />
            ) : (
              <GText g2="true" bold theme text={strings.w_home} />
            )}
          </Link>
          <GView style={{ marginLeft: "auto" }}>
            <GView
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              {settings && (
                <>
                  {localisation?.enabled == true &&
                  localisation?.options.length > 1 ? (
                    <GView
                      style={{
                        position: "relative",
                        padding: width <= 767 ? "0 6px" : "0 12px",
                      }}
                    >
                      <ChangeLanguage />
                    </GView>
                  ) : (
                    ""
                  )}
                </>
              )}
              {settings ? (
                !settings?.user?.id ? (
                  <a
                    onClick={() => setSigninvisible(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <UserIcon />
                    {strings.w_login}
                  </a>
                ) : (
                  <a
                    aria-controls={open ? "menu-list-grow" : undefined}
                    onClick={handleClick("bottom")}
                    aria-describedby={"id"}
                    aria-haspopup="true"
                    // onClick={handleToggle}
                    className="dropdownMenu"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <GAvtar
                      title={settings?.user?.title}
                      width={"40px"}
                      height={"40px"}
                      src={
                        settings?.user?.thumb_photo
                          ? ImgUrl("user") + "/" + settings?.user?.thumb_photo
                          : ""
                      }
                      imgType="user"
                      alt={settings?.user?.title}
                    />
                    &nbsp;&nbsp;
                    <GText
                      text={settings?.user?.title}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100px",
                        textAlign: "left",
                      }}
                    />
                  </a>
                )
              ) : (
                ""
              )}

              <Popper
                id={"id"}
                style={{ zIndex: "99", top: 20 }}
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                role={undefined}
                transition
                //    disablePortal
              >
                {({ TransitionProps }) => {
                  return (
                    <Grow {...TransitionProps}>
                      <LinkDropdown>
                        <ClickAwayListener onClickAway={handleClose}>
                          <ul>
                            <li>
                              <Link
                                to={{
                                  pathname: "/profile/bookings",
                                  state: { title: "Bookings" },
                                }}
                                state={{ title: "Bookings" }}
                                onClick={() => {
                                  setAnchorEl(null);
                                  setOpen(false);
                                }}
                              >
                                {strings?.w_manage_profile}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={{
                                  pathname: "/profile/bookings",
                                  state: { title: "Bookings" },
                                }}
                                state={{ title: "Bookings" }}
                                onClick={() => setOpen(false)}
                              >
                                {strings?.w_bookings}
                              </Link>
                            </li>
                            <li>
                              <a
                                role="button"
                                onClick={() => {
                                  setOpen(false);
                                  setAnchorEl(null);
                                  setWishlistVisible(true);
                                }}
                              >
                                {strings?.w_saved}
                              </a>
                            </li>
                            <li>
                              <a
                                role="button"
                                onClick={() => {
                                  setCpvisible(true);
                                  setAnchorEl(null);
                                  setOpen(false);
                                }}
                              >
                                {strings?.w_change_password}
                              </a>
                            </li>
                            <li>
                              <a
                                role="button"
                                onClick={() => {
                                  setOpen(false);
                                  setAnchorEl(null);
                                  setDialogOpen(true);
                                }}
                              >
                                {strings?.w_signout}
                              </a>
                            </li>
                          </ul>
                        </ClickAwayListener>
                      </LinkDropdown>
                    </Grow>
                  );
                }}
              </Popper>
            </GView>
          </GView>
        </GView>
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings.w_signout}
          text={strings.s_logout_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleLogout();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={wishlistVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setWishlistVisible(false)}
        style={{
          content: {
            width: "450px",
            padding: "20px 0",
            overflow: "hidden",
          },
        }}
        contentLabel="Favorite Restaurants Modal"
      >
        <SavedRestaurants
          wishlistVisible={wishlistVisible}
          setWishlistVisible={setWishlistVisible}
        />
      </Modal>

      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={epvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setEpvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <EditProfile epvisible={epvisible} setEpvisible={setEpvisible} />
      </Modal>

      <Modal
        isOpen={cpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCpvisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "310px",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Example Modal"
      >
        <ChangePassword cpvisible={cpvisible} setCpvisible={setCpvisible} />
      </Modal>

      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>
    </Header>
  );
};

export default HeaderView;
