import React, { useState, useEffect, useContext } from "react";
import { FlatList, View, Platform } from "react-native";
import { SettingsContext } from "../DataFactory/useSettings";
import OrderInfo from "./orderDetail";
import useDataFctory from "../useDataFactory";
import GButton from "../Elements/GButton";
import Modal from "react-modal";
import GInfo from "../Elements/gInfo";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";
import { useLocation } from "react-router-dom";
import { StringsContext } from "../DataFactory/useStrings";

export default function Orders(props) {
  const [strings] = useContext(StringsContext);
  let location = useLocation();
  const { width } = useWindowSize();
  const { settings } = useContext(SettingsContext);
  const [orderInfovisible, setOrderInfovisible] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(
    location?.state?.order_id || ""
  );
  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFctory("order", true, { order_status: "completed" });

  console.log(data);

  useEffect(() => {
    setBtnDisabled(false);
  }, [data]);

  useEffect(() => {
    // console.log(location);
    if (location?.state?.order_id) {
      setSelectedOrderId(location?.state?.order_id);
      setTimeout(function () {
        setOrderInfovisible(true);
        window.history.replaceState({}, "");
      }, 1000);
    }
  }, [location]);

  let count = Math.round(width / (Platform.OS === "web" ? 600 : 300));

  return (
    <React.Fragment>
      <GView
        style={{ display: "flex", margin: "10px 5px", alignItems: "center" }}
      >
        <GText g2="true" theme bold text={strings.w_all_bookings} />
        {data.length > 0 && (
          <>
            &nbsp;
            <GText
              g5
              med
              semi
              text={
                pagination.total > 1
                  ? ` (${pagination?.total} ${strings.w_bookings})`
                  : ` (${pagination?.total} ${strings.w_booking})`
              }
              style={{ fontStyle: "italic" }}
            />
          </>
        )}
      </GView>
      {loading === true ? (
        <Shimmer></Shimmer>
      ) : (
        <React.Fragment>
          <View style={{ flex: 1, flexDirection: "row" }}>
            <FlatList
              key={
                width <= 767
                  ? "s"
                  : width > 767 && width < 900
                  ? "m"
                  : width >= 900 && width < 1500
                  ? "l"
                  : "xl"
              }
              initialNumToRender={data.length}
              contentContainerStyle={{ flex: 1 }}
              keyExtractor={(data) => data.id}
              data={data}
              numColumns={count}
              renderItem={({ item }) => (
                <div style={{ flex: 1 / count }}>
                  <div
                    onClick={() => {
                      setSelectedOrderId(item?.id);
                      setOrderInfovisible(true);
                    }}
                    style={{
                      background: "#fff",
                      padding: "12px 12px 20px",
                      margin: "8px",
                      cursor: "pointer",
                      border: "1px solid #f2f2f2",
                      height: "calc(100% - 54px)",
                    }}
                  >
                    <GInfo
                      title={`#${item?.id} - ${item?.status_display?.title}`}
                      subtitle={item?.product_title}
                      subtitle2={item?.starts_display}
                      photo={item?.product?.images[0]?.photo}
                      imgType="item"
                      titleTheme={false}
                      titleStyle={{
                        color: "#fff",
                        background: "#" + item.status_display?.color1,
                        display: "inline-block",
                        padding: "1px 12px",
                        fontSize: "14px",
                        borderRadius: "20px",
                        fontWeight: "400",
                        fontStyle: "italic",
                        marginBottom: "5px",
                      }}
                      subtitleStyle={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      imgStyle={{
                        width: "90px",
                        height: "70px",
                        borderRadius: "10px",
                      }}
                      style={{ flexFlow: "row-reverse" }}
                    />
                    {settings?.time_charges == 1 && (
                      <GText
                        semi
                        text={settings.currency_symbol + item?.amount_display}
                      />
                    )}
                  </div>
                </div>
              )}
              ListEmptyComponent={Placeholder}
            />
          </View>

          {pagination.next_page_url && (
            <GButton
              loading={btnDisabled}
              variant="condensed"
              children={strings.w_load_more}
              type="button"
              onClick={() => {
                setBtnDisabled(true);
                loadMore();
              }}
              style={{
                margin: "10px auto",
                width: "150px",
                justifyContent: "center",
                display: "flex",
              }}
            />
          )}
        </React.Fragment>
      )}
      <Modal
        isOpen={orderInfovisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          setOrderInfovisible(false);
        }}
        style={{
          content: {
            width: "450px",
            padding: "20px 0px",
            overflow: "hidden",
          },
        }}
        contentLabel="Order Detail Modal"
      >
        <OrderInfo
          orderInfovisible={orderInfovisible}
          setOrderInfovisible={setOrderInfovisible}
          linkedId={selectedOrderId}
          settings={settings}
          onStatusUpdate={() => refreshData({ order_status: "completed" })}
          onCancelOrder={() => refreshData({ order_status: "completed" })}
        />
      </Modal>
    </React.Fragment>
  );
}
