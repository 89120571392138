import React, { useContext } from "react";
import styled from "styled-components";
import GButton from "./GButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { StringsContext } from "../DataFactory/useStrings";

const ConfirmDialog = styled.div`
  background: #999;

  .g-dialog {
    background: #fff;

    &.MuiSkeleton-rect {
      margin-top: 0px;
      margin-bottom: 25px;
    }
    &.MuiSkeleton-text {
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }
`;

function GConfirmDialog(props) {
  const [strings] = useContext(StringsContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ConfirmDialog>
      <Dialog
        // fullScreen={fullScreen}
        fullWidth={true}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 5px" }}
        >
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <GButton onClick={props.handleClose} variant="text">
            {props.handleCloseText ? props.handleCloseText : strings.w_cancel}
          </GButton>
          {props.onConfirmClose ? (
            <GButton onClick={props.onConfirmClose} variant="text">
              {strings.w_confirm}
            </GButton>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </ConfirmDialog>
  );
}

export default GConfirmDialog;
