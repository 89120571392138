import React, { useState } from "react";
import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as PhotoCamera } from "../assets/images/camera.svg";
import { FileApiService, addDefaultSrc } from "../services";
import { ReactComponent as DoneAllIcon } from "../assets/images/doneAll.svg";
import AvatarEditor from "react-avatar-editor";
import Loader from "../assets/images/loader.svg";

export const GImagePicker = (props) => {
  //SET DEFAULTS
  const height = props.height || 100;
  const width = props.width || 100;
  const radius = props.radius || height / 10;
  const loaderSize = props.loaderSize || height / 3;
  const buttonSize = props.buttonSize || height / 3 > 30 ? height / 3 : 30;
  const title = props.title || "Choose an Image";
  const type = props.type;
  if (!type) {
    throw new Error("type Prop is missing.");
  }
  const url = props.type;
  const editorProps = props.editorProps || {};
  const serverProps = props.serverProps || {};

  const [file, setFile] = useState(props.default);
  const [uploading, setUploading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [editorOpen, setEditorOpen] = useState(false);

  const openPicker = () => document.getElementById("file_picker").click();
  const handleChoose = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
    if (props.editor) {
      setEditorOpen(true);
    } else {
      setUploading(true);
      if (props.setBtnLoading) {
        props.setBtnLoading(true);
      }
      onClickHandler(event.target.files[0]);
    }
  };
  const onClickHandler = (fileUploaded) => {
    FileApiService("POST", url, fileUploaded)
      .then((response) => {
        console.log(response);
        setUploading(false);
        if (props.setBtnLoading) {
          props.setBtnLoading(false);
        }
        if (response) {
          enqueueSnackbar(response.message);
          if (response.status_code === 0) {
            setFile(null);
            document.getElementById("file_picker").value = null;
            return;
          }
          if (props.onSuccess) {
            props.onSuccess(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const styles = {
    container: {
      marginTop: 10,
      // backgroundColor: GColors.background2,
      height: height,
      width: width,
      borderRadius: radius,
      display: "table",
      position: "relative",
    },
    label: {
      display: "table-cell",
      textAlign: "center",
      verticalAlign: "middle",
      fontSize: 14,
      color: "#777",
      cursor: "pointer",
    },
    image: {
      position: "absolute",
      top: 0,
      left: 0,
      borderRadius: radius,
      borderColor: "red",
    },
    loader: {
      position: "absolute",
      top: 0,
      left: 0,
      height: height,
      width: width,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    },
    button: {
      height: buttonSize,
      width: buttonSize,
      borderRadius: buttonSize / 2,
      backgroundColor: "#fff",
      position: "absolute",
      right: -buttonSize / 8,
      bottom: -buttonSize / 8,
      cursor: "pointer",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    },
  };

  return (
    <React.Fragment>
      <input
        type="file"
        id="file_picker"
        style={{ display: "none" }}
        onChange={handleChoose}
      ></input>
      <div style={{ ...styles.container, ...props.style }}>
        {!file ? (
          <p style={styles.label} onClick={openPicker}>
            {" "}
            {title}{" "}
          </p>
        ) : (
          <img
            id="sss"
            src={file}
            height={height}
            width={width}
            border={0}
            style={styles.image}
            alt=""
            onError={addDefaultSrc}
          />
        )}

        <C if={uploading}>
          <div style={styles.loader}>
            <img
              src={Loader}
              alt="loading"
              style={{ verticalAlign: "middle", float: "right" }}
            />
          </div>
        </C>

        <C if={file && !uploading}>
          <div style={styles.button} onClick={openPicker}>
            <PhotoCamera
              style={{ fontSize: buttonSize / 2, color: "#000", width: "20px" }}
            />
          </div>
        </C>
      </div>

      <C if={file && editorOpen}>
        <Editor
          onSave={(f) => {
            setFile(URL.createObjectURL(f));
            setEditorOpen(false);
            setUploading(true);
            if (props.setBtnLoading) {
              props.setBtnLoading(true);
            }
            onClickHandler(
              new File([f], "filename." + f.type.split("/")[1], {
                type: f.type,
              })
            );
          }}
          file={file}
          props={editorProps}
          onClose={() => {
            setEditorOpen(false);
            setFile(props.default ? props.default : null);
            document.getElementById("file_picker").value = null;
          }}
        />
      </C>
    </React.Fragment>
  );
};

export const Editor = ({ props, file, onSave, onClose }) => {
  //SET DEFAULTS
  const height = props.height || 400;
  const width = props.width || 400;
  const radius = props.radius || height / 10;
  const [editor, setEditor] = useState(null);
  const setEditorRef = (editor) => setEditor(editor);
  const handleClose = (e) => {
    if (e.target.id !== "2737") {
      return;
    }
    onClose();
  };
  const [scale, setScale] = useState(1);

  const styles = {
    slider: {
      position: "absolute",
      left: 20,
      bottom: 24,
    },
    button: {
      position: "absolute",
      right: 10,
      bottom: 10,
      textTransform: "none",

      height: 30,
      width: 30,
      borderRadius: 15,
      backgroundColor: "#43444A",
      cursor: "pointer",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    },
  };

  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        <AvatarEditor
          ref={setEditorRef}
          image={file}
          width={width}
          height={height}
          border={50}
          borderRadius={radius}
          color={[34, 150, 243, 0.9]} // RGBA
          scale={scale}
          rotate={0}
          style={{ borderRadius: 10 }}
        />

        <input
          type="range"
          id="slider"
          className="slider"
          name="vol"
          min="0.5"
          step="0.1"
          max="3"
          value={scale}
          onChange={(v) => setScale(v.target.value)}
          style={styles.slider}
        ></input>

        <IconButton
          aria-label="delete"
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
          }}
          onClick={() => {
            const canvas = editor.getImageScaledToCanvas().toDataURL();
            let imageURL;
            fetch(canvas)
              .then((res) => res.blob())
              .then((blob) => {
                imageURL = window.URL.createObjectURL(blob);
                onSave(blob);
              });
          }}
        >
          <DoneAllIcon fontSize="small" />
        </IconButton>
      </div>
    </React.Fragment>
  );
};

export const C = (props) => !!props.if && props.children;

export default GImagePicker;
