import React, { useContext, useState } from "react";
import { ApiService } from "../services";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { StringsContext } from "../DataFactory/useStrings";

export default function ChangePassword(props) {
  const [strings] = useContext(StringsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const initialValues = {
    old: "",
    new: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    console.log(values);

    ApiService({
      method: "POST",
      route: "c/change-password",
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setCpvisible(false);
      }
      setBtnLoading(false);
      enqueueSnackbar(response.data.message);
    });
  };

  const validationSchema = Yup.object({
    old: Yup.string().required(strings.w_required),
    new: Yup.string()
      .required(strings.w_required)
      .min(6, strings.s_new_password_validation)
      .max(15),
  });

  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setCpvisible(false)}
        />
        {strings.w_change_password}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <FormikControl
              control="input"
              type="text"
              placeholder={strings.s_current_password}
              name="old"
            />

            <FormikControl
              control="input"
              type="text"
              placeholder={strings.s_new_password}
              name="new"
            />
            <br />
            <GButton
              variant="condensed"
              children={strings.w_update}
              style={{ margin: "0 15px", width: "92%" }}
              type="submit"
              loading={isBtnLoading}
            />
          </Form>
        </Formik>
      </div>
    </React.Fragment>
  );
}
