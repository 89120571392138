import React, { useContext } from "react";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function PrivacyPolicy() {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  return (
    <div className="main-content">
      <GText g2="true" bold text={strings.w_privacy_policy} />
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px",
          margin: "20px 0",
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: settings?.privacy_policy }}
        ></div>
      </GView>
      <br />
    </div>
  );
}
