import React, { useEffect, useState } from "react";
import GText from "../Elements/GText";
import { ImgUrl } from "../services";
import styled from "styled-components";
import GButton from "../Elements/GButton";
import { GCardShimmer } from "../Elements/gShimmer";
import useWindowSize from "../DataFactory/useWindowSize";
import GView from "../Elements/GView";

const ContentDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 25px 0;
  .img {
    width: 50%;
    text-align: center;
    img {
      max-width: 75%;
      padding: 20px 15px;
      box-sizing: border-box;
    }
  }

  .text {
    padding: 20px 30px;
    width: 50%;
    box-sizing: border-box;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    .img {
      width: 100%;
      img {
        max-width: 90%;
        padding: 10px;
      }
    }
    .text {
      padding: 20px 10px;
      width: 90%;
    }
  }
`;

const ContView = styled.div`
  flex: 50%;
  margin-bottom: 20px;
  div {
    margin: 8px 0;
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      fill: ${({ theme }) => theme.body};
    }
  }
`;

export default function HomeContent(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [pluginData, setPluginData] = useState([]);
  const { width } = useWindowSize();
  useEffect(() => {
    // console.log(props);
    setPluginData(props.data);
    setIsLoading(false);
  }, [props]);
  return (
    <>
      {isLoading === true ? (
        <GCardShimmer />
      ) : (
        pluginData &&
        pluginData.map((p, i) => {
          return (
            <div style={{ background: p.color }} key={i}>
              <div
              // className="main-content"
              >
                <ContentDiv>
                  {p.align === "left" && (
                    <div
                      className="img"
                      style={{
                        textAlign: width <= 767 ? "center" : p.align,
                      }}
                    >
                      <img
                        src={ImgUrl("web") + "/" + p.image}
                        alt={p.title}
                        width="auto"
                        height="auto"
                      />
                    </div>
                  )}
                  <div className="text">
                    <GText g1={"true"} bold text={p.title} />
                    <GText
                      g4
                      med
                      text={p.description}
                      style={{
                        padding: "15px 0 30px",
                        lineHeight: "25px",
                        fontSize: "17px",
                      }}
                    />

                    {p.b_title &&
                      (p.b_link ? (
                        <a
                          href={p.b_link}
                          target="_blank"
                          rel="noreferrer nofollow"
                        >
                          <GButton
                            variant="condensed"
                            children={p.b_title}
                            style={{
                              fontSize: "17px",
                              borderRadius: "5px",
                              padding: "0.8rem 4rem",
                            }}
                          />
                        </a>
                      ) : (
                        <GButton
                          variant="condensed"
                          children={p.b_title}
                          style={{
                            fontSize: "17px",
                            borderRadius: "5px",
                            padding: "0.8rem 4rem",
                            cursor: "auto",
                          }}
                        />
                      ))}
                  </div>
                  {p.align === "right" && (
                    <div
                      className="img"
                      style={{
                        textAlign: width <= 767 ? "center" : p.align,
                      }}
                    >
                      {/* <LazyLoad height={'auto'}> */}
                      <img
                        src={ImgUrl("web") + "/" + p.image}
                        alt={p.title}
                        width="auto"
                        height="auto"
                      />
                      {/* </LazyLoad> */}
                    </div>
                  )}
                </ContentDiv>
              </div>
            </div>
          );
        })
      )}

      {/* {props.content && props.content.length > 0 && (
        <GView
          style={{
            display: "flex",
            gap: "5%",
            flexDirection: width > 767 ? "row" : "column",
            margin: "30px 0 20px",
          }}
        >
          {props.content.map((c, i) => {
            return (
              <ContView key={i}>
                <GView>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/features/${c?.icon}`}
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  <GText
                    g4
                    semi
                    text={c.title}
                    style={{ width: "calc(100% - 20px)" }}
                  />
                </GView>
                <div
                  dangerouslySetInnerHTML={{ __html: c.description }}
                  style={{ whiteSpace: "pre-line" }}
                />
              </ContView>
            );
          })}
        </GView>
      )} */}
    </>
  );
}
