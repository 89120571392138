import React, { useState, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import { useSnackbar } from "notistack";
import { GImagePicker } from "../Elements/gImagePicker";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import moment from "moment-timezone";

const gender = [
  { title: "Male", value: "male" },
  { title: "Female", value: "female" },
  { title: "Other", value: "other" },
];

export default function EditProfile(props) {
  const [strings] = useContext(StringsContext);
  const { settings, setSettings } = useContext(SettingsContext);
  const [isBtnLoading, setBtnLoading] = useState(false);
  console.log(settings.user);
  const initialValues = {
    first_name: settings?.user?.first_name || "",
    last_name: settings?.user?.last_name || "",
    email: settings?.user?.email || "",
    photo: settings?.user?.photo || "",
    thumb_photo: settings?.user?.thumb_photo || "",
    phone: settings?.user?.phone || "",
    dob: settings?.user?.dob ? new Date(settings?.user?.dob) : null,
    gender: settings?.user?.gender || "",
  };
  const { enqueueSnackbar } = useSnackbar();
  const [icon, setIcon] = useState("");

  const onSubmit = (values) => {
    setBtnLoading(true);
    if (!values.password) {
      delete values.password;
    }
    let sendData = { ...values };
    sendData.dob = sendData.dob
      ? moment(new Date(sendData.dob)).format("yyyy-MM-DD")
      : "";

    console.log(sendData);

    ApiService({ method: "PUT", route: "c/me", body: sendData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          settings.user = response.data.data;
          setSettings({ ...settings });
          // localStorage.setItem("settings", JSON.stringify(settings));
          props.setEpvisible(false);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required(strings.w_required)
      .min("2", strings.s_first_name_character_validation),
    email: Yup.string()
      .email(strings.s_invalid_email_address)
      .required(strings.w_required),
  });

  if (props.epvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setEpvisible(false)}
        />
        {strings.w_edit_profile}
      </div>
      <div className="modal-body" style={{ paddingBottom: "10px" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <div style={{ margin: "0 15px" }}>
                <GImagePicker
                  type="user"
                  alt={settings?.user?.first_name}
                  height={100}
                  width={100}
                  radius={50}
                  editor
                  editorProps={{
                    height: 200,
                    width: 200,
                    radius: 100,
                  }}
                  serverProps={{
                    size: 200,
                  }}
                  setBtnLoading={setBtnLoading}
                  onSuccess={(v) => {
                    console.log(v);
                    setIcon(v.image);
                    values.photo = v.image;
                    values.thumb_photo = v.thumb_image;
                  }}
                  isPreviewImg={true}
                  default={ImgUrl("user") + "/" + settings?.user?.photo}
                />
              </div>
              <br />

              <FormikControl
                control="input"
                type="text"
                label={strings.w_first_name}
                name="first_name"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_last_name}
                name="last_name"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="email"
                label={strings.w_email_id}
                name="email"
                disabled={true}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_phone}
                name="phone"
                disabled={true}
              />

              <FormikControl
                control="date"
                label={strings.w_dob}
                name="dob"
                format="yyyy-MM-dd"
                onKeyDown={(e) => e.preventDefault()}
              />

              <FormikControl
                control="select"
                as="select"
                label={strings.w_gender}
                name="gender"
                options={gender}
                key_title="title"
                key_value="value"
              />

              <br />
              <GButton
                variant="condensed"
                children={strings.w_update}
                style={{ margin: "0 15px", width: "92%" }}
                type="submit"
                loading={isBtnLoading}
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
