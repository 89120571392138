import React from "react";
// import { Button} from '@material-ui/core';
import styled from "styled-components";

const Div = styled.div`
  &.inline {
    display: inline-block;
  }
`;
const InputLabel = styled.label`
  font-size: 0.9rem;
  padding: 6px 0;
  display: block;
  color: #616161;
  margin-top: 5px;
`;
const TextField = styled.input`
  padding: 0.65em;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  /* width: fit-content; */
  width: -webkit-fill-available;
  box-shadow: none;
  outline: 0;
  border: 1px solid #ddd;
  color: #555;
  font-size: 0.9rem;
  background: transparent;
  &::placeholder {
    color: #b2b2b2;
  }

  &.block {
    display: block;
  }

  &.inline {
    display: inline-block;
  }
  &:disabled {
    background: #f5f5f5;
  }

  &::placeholder {
    font-style: italic;
  }
`;

const Textarea = styled.textarea`
  padding: 0.65em;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  /* width: fit-content; */
  width: -webkit-fill-available;
  box-shadow: none;
  outline: 0;
  border: 1px solid #ddd;
  color: #555;
  font-size: 0.9rem;
  font-family: sans-serif;
`;

const inputDiv = {
  padding: "5px",
};

function GInput(props) {
  const onBlurHandler = (e) => {
    // if (!e.target.value) {
    //   return;
    // }
    if (props.onBlur) {
      props.onBlur(e.target.value, props.name);
    }
  };

  return (
    <Div
      className={props.mainClass}
      style={Object.assign({}, inputDiv, props.divStyle)}
    >
      {props.label ? <InputLabel>{props.label}</InputLabel> : ""}
      {props.as === "textarea" ? (
        <Textarea
          rows={props.rows || "4"}
          name={props.name}
          style={props.style}
          id={props.id}
          placeholder={props.placeholder}
          onChange={props.onChange}
        >
          {props.value}
        </Textarea>
      ) : (
        <TextField
          type={props.type}
          value={props.value}
          className={props.display}
          name={props.name}
          style={props.style}
          id={props.id}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onKeyPress={props.onKeyPress}
          onBlur={onBlurHandler}
          disabled={props.disabled}
          min={props.min}
          max={props.max ? props.max : ""}
          maxLength={props.maxLength}
          {...props}
        ></TextField>
      )}
    </Div>
  );
}

export default GInput;
