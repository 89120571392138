import React, { useContext } from "react";
import styled from "styled-components";
import OrdersIcon from "../assets/images/orders.png";
import AddressIcon from "../assets/images/address.png";
import { Link } from "react-router-dom";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";

export const Plink = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 200px;
  margin-right: 20px;
  vertical-align: top;
  display: flex;

  @media (max-width: 767px) {
    width: 100%;
    overflow: overlay;
  }

  li {
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 10px;
      color: #555;
      font-size: 15px;
      font-weight: 500;

      img {
        opacity: 0.4;
      }

      &.active {
        color: ${({ theme }) => theme.text};
        img {
          opacity: 1;
        }
      }
      @media (max-width: 767px) {
        text-align: center;
        font-size: 13px;
      }
    }
    @media (max-width: 767px) {
      width: 25%;
      min-width: 75px;
    }
  }
`;

export default function ProfileLinks(props) {
  const [strings] = useContext(StringsContext);
  const { width } = useWindowSize();

  const ProfileRoutes = [
    {
      title: strings.w_bookings,
      icon: OrdersIcon,
      link: "bookings",
      match_url: "/profile/bookings",
    },
    {
      title: strings.w_addresses,
      icon: AddressIcon,
      link: "address",
      match_url: "/profile/address",
    },
  ];
  return (
    <Plink style={{ flexDirection: width <= 767 ? "row" : "column" }}>
      {ProfileRoutes.map((route, i) => {
        return (
          <li key={i}>
            <Link
              to={route.link}
              className={props.url === route.match_url ? "active" : ""}
              style={{ flexDirection: width <= 767 ? "column" : "row" }}
            >
              <img
                src={route.icon}
                alt="orders"
                style={{
                  width: "19px",
                  marginRight: width <= 767 ? "0" : "10px",
                  marginBottom: width <= 767 ? "10px" : "0",
                }}
              />
              {route.title}
            </Link>
          </li>
        );
      })}
    </Plink>
  );
}
