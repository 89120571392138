import React, { useState, useContext, useEffect, useRef } from "react";
import GView from "../Elements/GView";
import styled from "styled-components";
import { ReactComponent as Table2 } from "../assets/images/tableIcons/2.svg";
import { ReactComponent as Table4 } from "../assets/images/tableIcons/4.svg";
import { ReactComponent as Table6 } from "../assets/images/tableIcons/6.svg";
import { ReactComponent as Table8 } from "../assets/images/tableIcons/8.svg";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import OrderPlaced from "../Components/orderPlaced";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";
import { SettingsContext } from "../DataFactory/useSettings";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {
  getMinDate,
  getMaxDate,
  getTimeArray,
  getFormattedDate,
} from "../Components/utilCalendar";
import MDatePicker, { utils } from "react-modern-calendar-datepicker";
import { TouchableOpacity } from "react-native-web";
import GSelectbox from "../Elements/gSelectbox";
import GInput from "../Elements/gInput";
import {
  ClickAwayListener,
  Button,
  Popper,
  Grow,
  Paper,
  MenuItem,
  MenuList,
  ButtonGroup,
} from "@mui/material";
import { ReactComponent as ArrowDropDownIcon } from "../assets/images/arrowDown.svg";

const TableView = styled.div`
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 6px;
  display: inline-flex;
  height: 46px;
  cursor: pointer;
  align-items: center;

  &.selected {
    border: 1px solid ${({ theme }) => theme.body};
    svg {
      fill: ${({ theme }) => theme.body};

      g {
        fill: ${({ theme }) => theme.body};

        rect,
        circle {
          stroke: ${({ theme }) => theme.body};
        }
      }
    }
  }

  svg {
    width: 100%;
    object-fit: none;
  }
`;

const GTableUsersView = styled.div`
  .css-zqcytf-MuiButtonGroup-root,
  .css-tn4v5h {
    box-shadow: none;
    .MuiButtonGroup-grouped,
    .css-tn4v5h .MuiButtonGroup-grouped {
      background: transparent;
      color: #000;
      padding: 0;
      min-width: 35px;
    }
  }
`;

const tableData = [
  {
    value: "2",
  },
  {
    value: "4",
  },
  {
    value: "6",
  },
  {
    value: "8",
  },
];
const options = [2, 3, 4, 5, 6, 7, 8];

export default function OrderCheckout(props) {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [checkoutData, setCheckoutData] = useState({
    product_id: props?.productId,
    lat: props.userLocation?.lat,
    lng: props.userLocation?.lng,
    users: "",
    starts: "",
    name: "",
    notes: "",
    gateway: { identifier: "cash" },
  });
  const [showOrderSuccess, setShowOrderSuccess] = useState(false);
  const [placedOrderId, setOrderId] = useState("");
  const [range, setRange] = useState({ from: null, to: null });
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);
  const [selectedTable, setSelectedTable] = React.useState("");

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function placeOrder() {
    setBtnLoading(true);
    // return;
    ApiService({ method: "POST", route: "c/orders", body: checkoutData }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderId(response.data.data.id);
          setShowOrderSuccess(true);
        } else {
          enqueueSnackbar(response.data.message);
          setBtnLoading(false);
          return;
        }
      }
    );
  }

  useEffect(() => {
    setCheckoutData({
      ...checkoutData,
      starts: getFormattedDate(range.from, true, settings),
    });
  }, [range]);

  return (
    <>
      <GView
        style={{
          margin: "30px 15px",
          boxShadow: "rgb(187 187 187 / 36%) -1px -1px 10px",
          padding: "25px 20px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <GView style={{ textAlign: "center" }}>
          <GText g5 semi text={strings.w_select_table} />
          <GView
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0",
              gap: "6px",
              justifyContent: "center",
            }}
          >
            {tableData.map((t, i) => {
              return (
                <TableView
                  key={i}
                  className={t.value == selectedTable ? "selected" : ""}
                  onClick={() => {
                    setSelectedTable(t.value);
                    setCheckoutData({ ...checkoutData, users: t.value });
                  }}
                >
                  {t.value == 2 ? (
                    <Table2 />
                  ) : t.value == 4 ? (
                    <Table4 />
                  ) : t.value == 6 ? (
                    <Table6 />
                  ) : (
                    <Table8 />
                  )}
                  {/* <img src={t.icon} /> */}
                </TableView>
              );
            })}
          </GView>
          {selectedTable && (
            <GTableUsersView
              style={{
                margin: "5px auto",
                border: "1px solid #e2e2e2",
                borderRadius: "20px",
                width: "auto",
                display: "inline-block",
                padding: "4px 15px",
                fontSize: "14px",
                color: "#333",
              }}
            >
              {selectedTable + " | " + strings.w_table_for + " "}

              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
              >
                {/* <Button></Button> */}
                <Button
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={() => setOpen((prevOpen) => !prevOpen)}
                >
                  {checkoutData?.users} &nbsp;
                  <ArrowDropDownIcon style={{ width: 10, height: 10 }} />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 1 }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              selected={option === checkoutData.users}
                              onClick={(event) => {
                                setCheckoutData({
                                  ...checkoutData,
                                  users: option,
                                });
                                setOpen(false);
                                if (option % 2 == 1) {
                                  setSelectedTable(option + 1);
                                } else {
                                  setSelectedTable(option);
                                }
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </GTableUsersView>
          )}
        </GView>

        <GView>
          <GInput
            type="text"
            name={"name"}
            id={"name"}
            autoComplete={"off"}
            value={checkoutData.name || ""}
            placeholder={strings.w_enter_your_name}
            display="block"
            onChange={(e) => {
              console.log(e.target.value);
              setCheckoutData({
                ...checkoutData,
                name: e.target?.value || "",
              });
            }}
            style={{
              padding: "5px 15px",
              height: "31px",
              color: "#333",
            }}
            divStyle={{ padding: 0 }}
          />
        </GView>

        <GView
          style={{
            border: "1px solid #e2e2e2",
            borderRadius: "20px",
            padding: "7px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarHourly range={range} setRange={setRange} />
        </GView>

        <GView>
          <GInput
            as={"textarea"}
            rows={2}
            name={"notes"}
            id={"notes"}
            autoComplete={"off"}
            value={checkoutData.notes || ""}
            placeholder={strings.w_other_details}
            onChange={(e) => {
              console.log(e.target.value);
              setCheckoutData({
                ...checkoutData,
                notes: e.target?.value || "",
              });
            }}
            style={{
              padding: "10px 15px",
              color: "#333",
            }}
            divStyle={{ padding: 0 }}
          />
        </GView>
        <GButton
          variant="condensed"
          loading={isBtnLoading}
          children={strings.w_reserve}
          onClick={() => {
            if (settings?.user?.id) {
              placeOrder();
            } else {
              props.setSigninvisible(true);
            }
          }}
          style={{
            width: "100%",
            borderRadius: "5px",
            fontSize: "15px",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 500,
            marginTop: "10px",
          }}
        ></GButton>
      </GView>
      <Modal
        isOpen={showOrderSuccess}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setShowOrderSuccess(true)}
        style={{
          content: {
            width: "350px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
          },
        }}
        contentLabel="Item Info Modal"
      >
        <OrderPlaced
          orderId={placedOrderId}
          showOrderSuccess={showOrderSuccess}
        />
      </Modal>
    </>
  );
}

const CalendarHourly = ({ range, setRange }) => {
  const [strings] = useContext(StringsContext);
  const { isBeforeDate } = utils();
  const minimumDate = getMinDate();
  const maxDate = getMaxDate();
  const { settings } = useContext(SettingsContext);

  const renderCustomInput = (ref, date) => (
    <TouchableOpacity ref={ref}>
      {date ? (
        <GText semib text={`${date.day}/${date.month}/${date.year}`} />
      ) : (
        <GText text={strings.s_select_date} />
      )}
    </TouchableOpacity>
  );

  useEffect(() => {
    if (range.from && range.to && isBeforeDate(range.to, range.from)) {
      setRange({ ...range, to: range.from });
    }
  }, [range.from]);

  return (
    <>
      {/* DATE FROM */}
      <GView style={{ padding: "0 5px" }}>
        <MDatePicker
          colorPrimary={settings?.web?.web_color}
          value={range.from}
          onChange={(s) => {
            setRange({
              ...range,
              from: { ...s },
              to: range.to?.day ? { ...range.to } : null,
            });
          }}
          renderInput={({ ref }) => renderCustomInput(ref, range.from)}
          minimumDate={minimumDate}
          maximumDate={maxDate}
          calendarClassName="custom-responsive-calendar"
          calendarPopperPosition="bottom"
        />
      </GView>
      <GView style={{ position: "relative" }}>
        <GSelectbox
          placeholder={strings.w_choose_time}
          defaultvalue={range?.from?.time || ""}
          data={getTimeArray(range, "from")}
          id="time"
          option_key="title"
          option_value="value"
          onChange={(time) => {
            console.log(time);
            setRange({
              ...range,
              from: { ...range.from, time },
              to: range.to?.day ? { ...range.to } : null,
            });
          }}
          style={{
            color: "#555",
            width: "auto",
            border: "0",
            margin: "0",
            padding: "2px 2em 2px 5px",
            fontSize: "0.9rem",
          }}
          arrowStyle={{
            position: "absolute",
            width: "10px",
            height: "10px",
            right: "12px",
            top: "8px",
            paddingRight: "0",
            paddingLeft: "5px",
          }}
        />
      </GView>
    </>
  );
};
