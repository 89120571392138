import React, { useEffect, useState } from "react";

const UserLocContext = React.createContext(); // line A - creating the context

const UserDefaultLocation = ({ children }) => {
  const [userLocation, setUserLocation] = useState({});

  const updateLocation = (location) => {
    setUserLocation(location);
    localStorage.setItem("location", JSON.stringify(location));
  };

  useEffect(() => {
    let locCoordinates = localStorage.getItem("location");

    console.log(locCoordinates);
    locCoordinates = locCoordinates
      ? JSON.parse(locCoordinates)
      : locCoordinates;
    // console.log(locCoordinates);
    setUserLocation(locCoordinates);
  }, []);

  return (
    <UserLocContext.Provider value={{ updateLocation, userLocation }}>
      {children}
    </UserLocContext.Provider>
  );
};

export { UserDefaultLocation, UserLocContext };
