import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import { ReactComponent as FilterIcon } from "../assets/images/filter.svg";
import { StringsContext } from "../DataFactory/useStrings";

export default function StoreFilters(props) {
  const [strings] = useContext(StringsContext);
  const [filterOptions, setFilterOptions] = useState(props.data || []);
  useEffect(() => {
    setFilterOptions(props.data);
  }, [props]);

  const initialValues = props.data.reduce((a, v) => ({
    ...a,
    [a?.identifier]:
      a?.type == "single" ? (a?.selectedVal ? a.selectedVal : "") : [],
    [v?.identifier.toString()]:
      v?.type == "single" ? (v?.selectedVal ? v.selectedVal : "") : [],
  }));

  const onSubmit = (values) => {
    console.log(values);
    props.onSubmit(values);
  };
  return (
    <div
      style={{
        margin: props.isPopup ? "0" : "0 30px 0 0",
        border: props.isPopup ? "0" : "1px solid #e2e2e2",
        padding: props.isPopup ? "0" : "15px",
        borderRadius: "10px",
      }}
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values }) => {
          console.log(values);
          if (props.submitBtn === false && props.applyBtn == false) {
            // props.setSelectedFilterVal(values.filterValues);
            props.onSubmit(values);
          }

          return (
            <Form>
              <GView
                style={{
                  display: "flex",
                  padding: props.isPopup ? "0 20px 10px" : "0 0 10px",
                  alignItems: "center",
                  borderBottom: props.isPopup
                    ? "3px solid #e2e2e2"
                    : "1px solid #e2e2e2",
                }}
              >
                <FilterIcon
                  style={{
                    width: "18px",
                    height: "18px",
                    marginRight: "8px",
                  }}
                />
                <GText
                  g3
                  semi
                  text={strings.w_filters}
                  style={{ fontSize: "20px" }}
                />
                {props.applyBtn === true && (
                  <>
                    <GButton
                      variant="linkable"
                      children={strings.w_apply}
                      type="submit"
                      style={{
                        fontWeight: "600",
                        marginLeft: "auto",
                        fontStyle: "italic",
                        textDecoration: "underline",
                      }}
                    />
                    <GButton
                      variant="linkable"
                      children={strings.w_clear}
                      type="button"
                      style={{
                        color: "red",
                        fontWeight: "600",
                        fontStyle: "italic",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        props.onSubmit({});

                        props.data.map((d) => {
                          values[d.identifier.toString()] = "";
                        });
                      }}
                    />
                  </>
                )}
              </GView>

              <GView
                style={{
                  padding: props.isPopup ? "0 20px 10px" : "0",
                  height: props.isPopup ? "calc(50vh - 90px)" : "auto",
                  overflow: "auto",
                }}
              >
                {props.data.map((f, i) => (
                  <React.Fragment key={i}>
                    <GText
                      g4
                      semi
                      text={f.title}
                      style={{ margin: "20px 0 5px", fontSize: "17px" }}
                    />
                    {f.type == "single" ? (
                      <FormikControl
                        control="radio"
                        name={f.identifier.toString()}
                        options={f.value}
                        key_title="title"
                        key_value="value"
                        custom={true}
                        divstyle={{ display: "block", padding: "4px 0" }}
                        onClick={(e) => console.log(values.filterValues)}
                        mainStyle={{
                          padding: "4px 15px 4px 0",
                        }}
                      />
                    ) : (
                      <FormikControl
                        control="checkbox"
                        name={f.identifier.toString()}
                        options={f.value}
                        key_title="title"
                        key_value="value"
                        onClick={(e) => console.log(values.filterValues)}
                        maindivstyle={{
                          maxHeight: "calc(100vh - 46vh - 190px)",
                          overflow: "overlay",
                        }}
                        divstyle={{ padding: "4px 0" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </GView>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
