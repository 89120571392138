import React, { useContext, useState, useEffect } from "react";
import GView from "../Elements/GView";
import { ApiService } from "../services";
import GTabs from "../Elements/gTabs";
import useDataFctory from "../useDataFactory";
import { useSnackbar } from "notistack";
import GText from "../Elements/GText";
import ThumbUpAlt from "../assets/images/thumbs-up.svg";
import ThumbDownAlt from "../assets/images/thumbs-down.svg";
import { StringsContext } from "../DataFactory/useStrings";

export default function Faqs() {
  const [strings] = useContext(StringsContext);
  const faqTypesData = [
    { title: strings?.w_order, identifier: "order" },
    { title: strings?.w_offers, identifier: "offers" },
    { title: strings?.w_account, identifier: "account" },
    { title: strings?.w_other, identifier: "other" },
  ];

  const [faqTypes, setFaqTypes] = useState(faqTypesData);
  const [typeValue, setTypeValue] = useState(faqTypes[0].identifier);
  const { enqueueSnackbar } = useSnackbar();
  const { loading, Shimmer, Placeholder, data, refreshData, fullData } =
    useDataFctory("faqs", false);

  useEffect(() => {
    setFaqTypes(
      fullData?.options && fullData?.options.length > 0
        ? fullData?.options
        : faqTypesData
    );
    setTypeValue(
      fullData?.options && fullData?.options.length > 0
        ? fullData?.options[0]?.identifier
        : faqTypes[0].identifier
    );
  }, [fullData]);

  const handleChangeTab = (newVal) => {
    console.log(newVal);
    setTypeValue(newVal);
  };
  const handleQuery = (value, id) => {
    let userData = JSON.parse(localStorage.getItem("settings"));
    if (!userData.user?.id) {
      enqueueSnackbar(strings.s_login_to_send_feedback);
      return;
    }
    ApiService({
      method: "POST",
      route: "c/faq/feedback",
      body: { faq_id: id, liked: value },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          refreshData();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="main-content">
      <GText g2="true" bold text={strings.w_help_faqs} />
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px",
          margin: "20px 0",
        }}
      >
        <GTabs
          routes={faqTypes}
          active={typeValue}
          key_value={"identifier"}
          key_title={"title"}
          type="multiple"
          onClick={handleChangeTab}
        ></GTabs>

        <GView style={{ padding: "20px 0" }}>
          {loading === true ? (
            <Shimmer />
          ) : data.filter((r) => r.faq_type === typeValue).length === 0 ? (
            <Placeholder />
          ) : (
            data
              .filter((r) => r.faq_type === typeValue)
              .map((f, i) => {
                return (
                  <GView key={i} style={{ margin: "20px 0" }}>
                    <GText g4 text={f.question} style={{ margin: "10px 0" }} />
                    <GText
                      dark
                      text={f.answer}
                      style={{ letterSpacing: "0", fontSize: "15.5px" }}
                    />
                    <div style={{ textAlign: "right" }}>
                      <h5
                        style={{
                          margin: "10px 0",
                          fontWeight: "400",
                          color: "#a2a2a2",
                          fontSize: "15px",
                        }}
                      >
                        {strings.s_was_this_ans_helpful}
                        <ul
                          style={{
                            display: "inline-flex",
                            color: "#ccc",
                            listStyleType: "none",
                            margin: "0 0 10px",
                            padding: "0 5px",
                          }}
                        >
                          <li
                            style={{ padding: "0 5px", cursor: "pointer" }}
                            onClick={() => handleQuery("1", f.id)}
                          >
                            <img
                              src={ThumbUpAlt}
                              style={{ width: "18px", verticalAlign: "bottom" }}
                            />
                          </li>
                          |
                          <li
                            style={{ padding: "0 5px", cursor: "pointer" }}
                            onClick={() => handleQuery("2", f.id)}
                          >
                            <img
                              src={ThumbDownAlt}
                              style={{ width: "18px", verticalAlign: "bottom" }}
                            />
                          </li>
                        </ul>
                      </h5>
                    </div>
                  </GView>
                );
              })
          )}
        </GView>
      </GView>
      <br />
    </div>
  );
}
