import React, { useContext, useState } from "react";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
// import { Visibility, VisibilityOff } from "@material-ui/icons";
import { ApiLoginService, ImgUrl } from "../services";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import VerifyOtpForm from "./verifyOtp";
import GText from "../Elements/GText";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";
import moment from "moment-timezone";
import { GMobileCountryCode } from "../Elements/gMobileCountryCode";

const gender = [
  { title: "Male", value: "male" },
  { title: "Female", value: "female" },
  { title: "Other", value: "other" },
];

export default function Signup(props) {
  const [strings] = useContext(StringsContext);
  const [verifyOtpvisible, setVerifyOtpvisible] = useState(false);
  const [signupData, setSignupData] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_prefix: "+355",
    phone: "",
    password: "",
    dob: null,
    gender: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    if (props.data) {
      delete values.password;
    }
    let sendData = { ...values };
    sendData.country_code = parseInt(sendData.phone_prefix);
    sendData.phone = parseInt(sendData.phone_prefix) + "" + sendData.phone;
    sendData.dob = sendData.dob
      ? moment(new Date(sendData.dob)).format("yyyy-MM-DD")
      : "";

    console.log(sendData);

    ApiLoginService("POST", "c/signup/otp", sendData).then((response) => {
      console.log(response);
      if (response.status_code === 1) {
        setSignupData(sendData);
        setVerifyOtpvisible(true);
      }
      setBtnLoading(false);
      enqueueSnackbar(response.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required(strings.w_required),
    email: Yup.string()
      .email(strings.s_invalid_email_address)
      .required(strings.w_required),
    phone: Yup.string()
      .matches(
        /^([+ ]*)([\d{1,2}]*)(\d{5}([- ]*)\d{5})$/g,
        strings.s_invalid_pjone_number
      )
      .min(9, strings.s_min_length_is + " 10")
      .max(14, strings.s_too_long)
      .required(strings.w_required),
    password: Yup.string()
      .required(strings.w_required)
      .min(6, strings.s_password_is_too_Short),
  });

  const getOauthToken = () => {
    var refresh_token_data = {
      username: signupData.email,
      password: signupData.password,
      grant_type: "password",
      client_id: 3,
      client_secret: "msndncuyftbegfnwteyufbtwefytewbufgwwye",
      provider: "customers",
    };
    console.log(refresh_token_data);

    ApiLoginService("POST", "oauth/token", refresh_token_data)
      .then(function (response) {
        if (response) {
          console.log(response);
          localStorage.setItem("uwAccessToken", response.access_token);
          localStorage.setItem("uwRefreshToken", response?.refresh_token);
          let now = (Date.now() / 1000) | 0;
          localStorage.setItem("uwExpiresIn", now + response.expires_in);
          props.setSignupvisible(false);
          window.location.reload(false);
        } else {
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onHandleVerify = () => {
    getOauthToken();
  };

  if (props.signupvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        &nbsp;&nbsp;
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setSignupvisible(false)}
        />
        {strings.w_sign_up}
      </div>

      {props.settings?.web?.web_logo ? (
        <img
          src={ImgUrl("web") + "/" + props.settings?.web?.web_logo}
          className="header-logo"
          alt={props.settings?.project_name}
        />
      ) : (
        <GText
          g2
          bold
          theme
          text={strings.w_logo}
          style={{ margin: "15px 30px 10px", width: "auto" }}
        />
      )}

      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ isValid, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings.w_first_name}
                name="first_name"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_last_name}
                name="last_name"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="email"
                label={strings.w_email_id}
                name="email"
                // className="noBorder"
              />

              <div style={{ margin: "15px 15px 5px" }}>
                <label>{strings?.w_phone}</label>

                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div>
                    <GMobileCountryCode
                      value={{
                        code: "+355",
                      }}
                      onSelect={(val) => {
                        console.log(val);
                        setFieldValue("phone_prefix", val.code);
                      }}
                      style={{
                        border: "1px solid #e2e2e2",
                        height: "38px",
                        paddingLeft: "5px",
                      }}
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      name="phone_prefix"
                      style={{ display: "none" }}
                      divstyle={{ margin: "0", padding: "0" }}
                    />
                  </div>
                  <FormikControl
                    control="numberInput"
                    type="text"
                    name="phone"
                    onChange={(event) => {
                      const re = /^[0-9.+\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("phone", event.target.value);
                      }
                    }}
                    divstyle={{ padding: "0", width: "inherit" }}
                    style={{ borderLeft: "0", marginTop: "0" }}
                  />
                </div>
              </div>

              <span style={{ display: "block", position: "relative" }}>
                <FormikControl
                  control="input"
                  type={isVisible === true ? "text" : "password"}
                  label={strings.w_password}
                  name="password"
                />
                <span
                  style={{ position: "absolute", right: "30px", top: "45px" }}
                >
                  {/* {isVisible === false ? (
                    <VisibilityOff
                      onClick={() => setIsVisible(true)}
                      style={{ fontSize: "20px", fill: "#a2a2a2" }}
                    />
                  ) : (
                    <Visibility
                      onClick={() => setIsVisible(false)}
                      style={{ fontSize: "20px", fill: "#a2a2a2" }}
                    />
                  )} */}
                </span>
              </span>

              <FormikControl
                control="date"
                label={strings.w_dob}
                name="dob"
                format="yyyy-MM-dd"
                onKeyDown={(e) => e.preventDefault()}
              />

              <FormikControl
                control="select"
                as="select"
                label={strings.w_gender}
                name="gender"
                options={gender}
                key_title="title"
                key_value="value"
              />
              <br />
              <GButton
                variant="condensed"
                disabled={!isValid}
                style={{
                  margin: "0 15px",
                  width: "calc(100% - 30px)",
                  textTransform: "uppercase",
                }}
                children={strings.w_sign_up}
                type="submit"
                loading={isBtnLoading}
              />
            </Form>
          )}
        </Formik>

        <GButton
          variant="linkable"
          style={{
            margin: "15px",
            width: "calc(100% - 30px)",
          }}
          children={strings.s_login_to_your_account}
          onClick={() => {
            props.setSigninvisible(true);
            props.setSignupvisible(false);
          }}
          type="button"
        />
      </div>

      <Modal
        isOpen={verifyOtpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setVerifyOtpvisible(false)}
        style={{
          content: {
            width: "350px",
            top: "0%",
            left: "0%",
            right: "0",
            bottom: "0",
            margin: "auto",
            minHeight: "370px",
            maxHeight: "400px",
            // transform: 'translate(0%, -50%)'
          },
        }}
        contentLabel="Verify OTP Modal"
      >
        <VerifyOtpForm
          verifyOtpvisible={verifyOtpvisible}
          setVerifyOtpvisible={setVerifyOtpvisible}
          type="signup"
          data={signupData}
          onVerify={() => onHandleVerify()}
        />
      </Modal>
    </React.Fragment>
  );
}
