import React, { useContext, useState } from "react";
import { ApiLoginService } from "../services";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { StringsContext } from "../DataFactory/useStrings";

export default function VerifySignupOtp(props) {
  const [strings] = useContext(StringsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);

  const initialValues = {
    otp: "",
    phone_otp: "",
    email_otp: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);

    if (props.type === "signup") {
      delete values.otp;
      let sendData = { ...props.data, ...values };
      console.log(sendData);

      ApiLoginService("POST", "c/signup", sendData).then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          props.onVerify();
          props.setVerifyOtpvisible(false);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.message);
        return;
      });
    } else {
      values.username = props.data.username;
      delete values.email_otp;
      delete values.phone_otp;

      console.log(values);

      ApiLoginService("POST", "c/verify-otp", values).then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          props.onVerify(values.otp);
          props.setVerifyOtpvisible(false);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.message);
        return;
      });
    }
  };

  const validationSchema = Yup.object({
    otp:
      props.type === "signup" ? "" : Yup.string().required(strings.w_required),
    email_otp:
      props.type === "signup" ? Yup.string().required(strings.w_required) : "",
    phone_otp:
      props.type === "signup" ? Yup.string().required(strings.w_required) : "",
  });

  if (props.verifyOtpvisible === false) {
    return null;
  }
  return (
    <div className="modal-body">
      <h4 style={{ margin: "5px 10px" }}>{strings.w_enter_otp}</h4>
      <p
        style={{
          fontSize: "14px",
          margin: "10px",
          lineHeight: "20px",
          color: "#333",
        }}
      >
        {strings.s_enter_otp_sent}
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isValid }) => (
          <Form>
            {props.type == "signup" ? (
              <>
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_phone_otp}
                  placeholder={strings.w_type_otp}
                  name="phone_otp"
                  style={{ height: "45px" }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_email_otp}
                  placeholder={strings.w_type_otp}
                  name="email_otp"
                  style={{ height: "45px" }}
                />
              </>
            ) : (
              <FormikControl
                control="input"
                type="text"
                placeholder={strings.w_type_otp}
                name="otp"
                style={{ height: "45px" }}
              />
            )}
            <br />
            <GButton
              variant="condensed"
              disabled={!isValid}
              style={{
                margin: "0 15px",
                width: "92%",
              }}
              children={strings.w_verify}
              type="submit"
              loading={isBtnLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
