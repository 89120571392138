import React, { useState, useContext } from "react";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GMap from "../Elements/gMaps";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import { StringsContext } from "../DataFactory/useStrings";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  console.log(match);
  return match ? match[1] : undefined;
}

export default function CreateAddress(props) {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [addressLocation] = useState(
    !props.isEmpty
      ? {
          lat: parseFloat(props.data.latitude),
          lng: parseFloat(props.data.longitude),
        }
      : { lat: 30.7046, lng: 76.7179 }
  );

  const addressTitle = [
    {
      key: strings.w_home,
      value: "home",
    },
    {
      key: strings.w_office,
      value: "office",
    },
    {
      key: strings.w_other_,
      value: "other",
    },
  ];
  const initialValues = {
    type: "saved",
    customer_id: "",
    title: !props.isEmpty
      ? props.data.title
        ? addressTitle.filter((at) => at.value == props.data.title).length > 0
          ? props.data.title
          : "other"
        : "home"
      : "home",
    other_title: !props.isEmpty
      ? props.data.title
        ? addressTitle.filter((at) => at.value == props.data.title).length > 0
          ? ""
          : props.data.title
        : props.data.title
      : "",
    line1: !props.isEmpty ? props.data.line1 : "",
    line2: !props.isEmpty ? props.data.line2 : "",
    name: !props.isEmpty ? props.data.name : "",
    phone: !props.isEmpty ? props.data.phone : "",
    latitude: !props.isEmpty ? props.data.latitude : addressLocation?.lat,
    longitude: !props.isEmpty ? props.data.longitude : addressLocation?.lng,
    notes: !props.isEmpty ? props.data.notes : "",
    city: !props.isEmpty ? props.data.city : "",
    state: !props.isEmpty ? props.data.state : "",
    country: !props.isEmpty ? props.data.country : "",
    zipcode: !props.isEmpty ? props.data.zipcode : "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);

    let sendValues = { ...values };
    sendValues.customer_id = settings?.user?.id;
    sendValues.title =
      sendValues.title == "other" ? sendValues.other_title : sendValues.title;
    console.log(sendValues);
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "c/addresses/" + props.data.id
      : "c/addresses";
    ApiService({ method: hitMethod, route: hitUrl, body: sendValues }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.onSubmit();
          props.setAdvisible(false);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    line1: Yup.string().required(strings.w_required),
  });

  if (props.advisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setAdvisible(false)}
        />
        {props.isEmpty === true
          ? strings.w_create_address
          : strings.w_edit_address}
      </div>
      <div
        className="modal-body"
        style={{
          padding: "0 20px",
          maxHeight: "calc(100vh - 80px)",
          overflow: "auto",
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GMap
                centerLocation={addressLocation}
                search={true}
                map={true}
                height="30vh"
                type="createAddress"
                onChange={(address, loc) => {
                  values.latitude = loc.lat();
                  values.longitude = loc.lng();
                  values.line1 = address.address;
                  values.city = address.city;
                  values.state = address.state;
                  values.country = address.country;
                  values.zipcode = address.zipcode;
                  setFieldValue("line1", address.address);
                }}
                padding={true}
                onLoad={!props.isEmpty ? false : true}
              />
              <FormikControl
                control="radio"
                name="title"
                options={addressTitle}
                key_title="key"
                selected={values.title}
                key_value="value"
                icons={true}
                style={{ display: "none" }}
                divstyle={{
                  display: "inline-block",
                  width: "33%",
                  border: "1px solid #d2d2d2",
                  textAlign: "center",
                  padding: "5px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
                labelStyle={{
                  boxSizing: "border-box",
                  fontSize: "14px",
                  margin: "3px 0",
                }}
              />
              {values.title === "other" && (
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.s_address_line_title}
                  name="other_title"
                />
              )}
              <FormikControl
                control="input"
                type="text"
                label={`${strings.s_address_line1}*`}
                name="line1"
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.s_address_line2}
                name="line2"
              />
              <br />
              <GButton
                variant="condensed"
                loading={isBtnLoading}
                children={!props.isEmpty ? strings.w_update : strings.w_create}
                type="submit"
                style={{ width: "calc(100% - 30px)", margin: "0 15px 15px" }}
              />{" "}
              {getIOSVersion() >= 14 ? (
                <div style={{ height: "80px" }}></div>
              ) : (
                ""
              )}
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
