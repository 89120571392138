import React, { useEffect, useState, useContext } from "react";
import { FlatList, View } from "react-native";
import GButton from "../Elements/GButton";
import { StringsContext } from "../DataFactory/useStrings";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import Placeholder from "../Placeholders/Placeholder";
import { GCardShimmer } from "../Elements/gShimmer";
import GItemListView from "../Elements/gItemListView";
import { UserLocContext } from "../DataFactory/useLocation";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import { SettingsContext } from "../DataFactory/useSettings";

export default function SavedRestaurants(props) {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const { userLocation } = useContext(UserLocContext);
  const [itemsData, setItemsData] = useState({
    data: [],
  });
  const [page, setPage] = useState("1");

  function getItems() {
    let sendData = {
      lat: userLocation?.lat,
      lng: userLocation?.lng,
      wishlist: true,
    };

    ApiService({
      method: "Post",
      route: "c/products?page=" + page,
      body: sendData,
    })
      .then((response) => {
        console.log(response);

        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setItemsData(response.data.data);
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    getItems();
  }, [page]);

  if (props.wishlistVisible === false) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        &nbsp;&nbsp;
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setWishlistVisible(false)}
        />
        {strings?.w_saved}
      </div>
      <div
        className="modal-body"
        style={{
          padding: "0 25px",
          overflow: "overlay",
        }}
      >
        {isLoading === true ? (
          <GCardShimmer></GCardShimmer>
        ) : (
          <>
            {itemsData?.data.length > 0 ? (
              <h5
                style={{ margin: "15px", color: "#777", fontStyle: "italic" }}
              >
                {itemsData?.data.length}{" "}
                {itemsData?.data.length === 1
                  ? " " + strings?.w_restaurant
                  : " " + strings?.w_restaurants}
              </h5>
            ) : (
              ""
            )}
            <View style={{ flex: 1, flexDirection: "row" }}>
              <FlatList
                // style={{ flex: 0 }}
                initialNumToRender={itemsData?.data.length}
                contentContainerStyle={{ flex: 1 }}
                keyExtractor={(items) => items.id}
                data={itemsData.data}
                numColumns={1}
                renderItem={({ item, ind }) => (
                  <>
                    <GItemListView data={item} key={ind} settings={settings} />
                  </>
                )}
                ListEmptyComponent={Placeholder}
                // onEndReachedThreshold={0.4}
                // onEndReached={loadMore}
              />
            </View>

            {itemsData.next_page_url ? (
              <GButton
                variant="condensed"
                children={strings?.w_load_more}
                type="button"
                onClick={() => getItems()}
                style={{ display: "flex", margin: "10px auto" }}
              />
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
}
