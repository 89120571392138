import React, { Component, useRef, useEffect } from "react";
import LocationIcon from "../assets/images/marker.png";
import MapStyles from "../jsonData/mapStyles.json";

const GMap = (props) => {
  const googleMapRef = useRef(null);
  let googleMap = null;
  // console.log(props);
  // list of icons

  // list of the marker object along with icon
  const markerList = props?.markers || [
    {
      lat: parseFloat(props.lat),
      lng: parseFloat(props.lng),
      icon: LocationIcon,
      title: props.address,
      type: "address",
    },
  ];
  //console.log(markerList);
  useEffect(() => {
    googleMap = initGoogleMap();
    var bounds = new window.google.maps.LatLngBounds();
    markerList.map((x) => {
      if (x.lat) {
        const marker = createMarker(x);
        const infoWindow = createInfoWindow(x);
        if (x?.id) {
          window.google.maps.event.addListener(marker, "click", function () {
            infoWindow.close(); // Close previously opened infowindow

            let info = x.data;
            infoWindow.setContent(
              '<h4 style="margin:2px 0;font-size:14px;cursor:pointer"class="infowindowstyle"  id="infoWindow_' +
                x.id +
                '">' +
                x.title +
                "</h4>"
            );

            window.google.maps.event.addListener(infoWindow, "domready", () => {
              var clickableItem = document.getElementById("infoWindow_" + x.id);
              clickableItem.addEventListener("click", () => {
                props.onSelectItemLocation(x.data);
              });
            });
            infoWindow.open(googleMap, marker);
          });
          // window.google.maps.event.addListener(
          //   marker,
          //   "click",
          //   (function (marker, x) {
          //     return function () {
          //       console.log(x);
          //       props.onSelectItemLocation(x.data);
          //     };
          //   })(marker, x)
          // );
        }
        bounds.extend(marker.position);
      }
    });
    //console.log(googleMap);
    // googleMap.fitBounds(bounds); // the map to contain all markers
    googleMap.setZoom(13);
  }, []);

  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      //   zoom: 25,
      center: {
        lat: parseFloat(props.lat),
        lng: parseFloat(props.lng),
      },
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
      styles: MapStyles,
    });
  };

  // create marker on google map
  const createMarker = (markerObj) =>
    new window.google.maps.Marker({
      position: { lat: markerObj?.lat, lng: markerObj?.lng },
      map: googleMap,
      title: markerObj.title,
      icon: {
        url: markerObj.icon,
        label: markerObj.title,
        // set marker width and height
        scaledSize: new window.google.maps.Size(40, 40),
      },
    });

  const createInfoWindow = (markerObj) =>
    new window.google.maps.InfoWindow({
      content:
        '<h4 style="margin:2px 0;font-size:14px">' + markerObj.title + "</h4>",
      disableAutoPan: true,
    });

  return (
    <div
      ref={googleMapRef}
      style={Object.assign(
        {
          maxWidth: "100%",
          height: "300px",
        },
        props.style
      )}
    />
  );
};

export default GMap;
