import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import GConfirmDialog from "./gConfirmDialog";
import GActions from "./gActionsStyle";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import Uncheck from "../assets/images/untick.png";
import GText from "./GText";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";

const ListCard = styled.div`
  padding: 0.9em;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;
  background: #fff;
  vertical-align: top;
  border: 1px solid #f2f2f2;
  margin: 5px;
  height: auto;
  overflow: overlay;

  label {
    display: flex;

    img {
      margin-top: 5px;
    }
  }

  span svg {
    width: 25px;
    height: 25px;
    margin-top: 5px;
    path {
      fill: ${({ theme }) => theme.body};
    }
  }
`;

export default function GAddressList(props) {
  const [strings] = useContext(StringsContext);
  const { width } = useWindowSize();
  const [addressData, setAddressData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(false);
  const [defaultDialogOpen, setDefaultDialogOpen] = useState(false);
  useEffect(() => {
    setAddressData(props.addressData);
  }, [props]);

  const handleDelete = (e) => {
    console.log(selectedAddressId);
    props.onDelete(selectedAddressId);
  };
  const handleDefault = () => {
    props.onSelectDefault(selectedAddressId);
  };

  return (
    <React.Fragment>
      <ListCard
        style={{
          border: props.orderAdress === true ? "0px" : "1px solid #f2f2f2",
          borderBottom: "1px solid #f2f2f2",
          padding:
            props.orderAdress === true || props.default === true
              ? "0.9rem"
              : "0px",
          overflow: "initial",
        }}
      >
        {props.default === true ? (
          <div
            className="radio"
            style={{
              cursor: "pointer",
              verticalAlign: "top",
            }}
          >
            <label>
              <input
                type="radio"
                value="cash"
                checked={addressData.default === 1}
                onChange={() => {
                  console.log(addressData.id);
                }}
                style={{
                  cursor: "pointer",
                  height: "17px",
                  width: "17px",
                  display: "none",
                }}
              />

              {addressData.default === 1 ? (
                <span className="active">
                  <Check style={{ fontSize: "1rem" }} />
                </span>
              ) : (
                <span
                  onClick={() => {
                    setSelectedAddressId(addressData.id);
                    setDefaultDialogOpen(true);
                  }}
                >
                  <img src={Uncheck} style={{ width: "25px" }} alt="" />
                </span>
              )}

              <div
                onClick={() => {
                  setSelectedAddressId(addressData.id);
                  setDefaultDialogOpen(true);
                }}
                style={{
                  marginLeft: "20px",
                  display:
                    props.default || props.orderAdress
                      ? "inline-block"
                      : "block",
                  width:
                    props.default || props.orderAdress
                      ? "calc(100% - 90px)"
                      : "100%",
                }}
              >
                <GText
                  g4
                  semi
                  text={addressData.title}
                  style={{ textTransform: "uppercase" }}
                />
                <GText
                  text={addressData.formatted}
                  style={{
                    height:
                      width <= 767
                        ? "auto"
                        : props.default === true
                        ? "80px"
                        : "auto",
                    letterSpacing: "0",
                    color: "#777",
                    fontSize: "14px",
                    padding: "5px 0",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: width > 767 ? "ellipsis" : "unset",
                    WebkitLineClamp: width > 767 ? "4" : "",
                    display: width > 767 ? "-webkit-box" : "block",
                  }}
                />
              </div>
              <div
                style={{
                  color: "#555",
                  fontWeight: "400",
                  display: "inline-block",
                  width: "40px",
                  minHeight: props.default ? "75px" : "auto",
                  textAlign: "right",
                }}
              >
                {props.dropdownActions === true ? (
                  <GActions
                    onEdit={() => props.onEdit(addressData)}
                    onDelete={() => {
                      setSelectedAddressId(addressData.id);
                      setDialogOpen(true);
                    }}
                    icons={true}
                    style={{ position: "relative" }}
                    menuStyle={{ fontSize: "15px" }}
                  />
                ) : (
                  ""
                )}
              </div>
            </label>
          </div>
        ) : (
          ""
        )}

        {props.orderAdress === true ? (
          <div
            className="radio"
            style={{
              cursor: "pointer",
              verticalAlign: "top",
            }}
          >
            <label>
              <input
                type="radio"
                value={addressData.id}
                checked={addressData.id === props.defaultAddress?.id}
                style={{
                  cursor: "pointer",
                  height: "17px",
                  width: "17px",
                  display: "none",
                }}
              />
              {addressData.id === props.defaultAddress?.id ? (
                <span className="active">
                  <Check style={{ fontSize: "1rem" }} />
                </span>
              ) : (
                <span
                  onClick={() => {
                    props.onSelectOrderAddress(addressData);
                  }}
                >
                  <img src={Uncheck} style={{ width: "25px" }} alt="" />
                </span>
              )}

              <div
                onClick={() => {
                  props.onSelectOrderAddress(addressData);
                }}
                style={{
                  marginLeft: "20px",
                  cursor: "pointer",
                  display:
                    props.default || props.orderAdress
                      ? "inline-block"
                      : "block",
                  width:
                    props.default || props.orderAdress
                      ? "calc(100% - 90px)"
                      : "100%",
                }}
              >
                <GText
                  g4
                  semi
                  text={addressData.title}
                  style={{ textTransform: "uppercase" }}
                />
                <GText
                  text={addressData.formatted}
                  style={{
                    height: props.default === true ? "80px" : "auto",
                    letterSpacing: "0",
                    color: "#777",
                    fontSize: "14px",
                    padding: "5px 0",
                  }}
                />
              </div>
              <div
                style={{
                  color: "#555",
                  fontWeight: "400",
                  display: "inline-block",
                  width: "40px",
                  minHeight: props.default ? "75px" : "auto",
                }}
                onClick={(event) => event.stopPropagation()}
              >
                &nbsp;&nbsp;
                {props.dropdownActions === true && (
                  <GActions
                    onEdit={(e) => {
                      e.stopPropagation();
                      props.onEdit(addressData);
                    }}
                    onDelete={(e) => {
                      e.stopPropagation();
                      setSelectedAddressId(addressData.id);
                      setDialogOpen(true);
                    }}
                    style={{
                      float: "right",
                      marginTop: "22px",
                      position: "relative",
                    }}
                  />
                )}
              </div>
            </label>
          </div>
        ) : (
          ""
        )}

        {dialogOpen === true && (
          <GConfirmDialog
            open={dialogOpen}
            title={strings.w_delete}
            text={strings.s_delete_subtitle}
            handleClose={() => setDialogOpen(false)}
            onConfirmClose={() => {
              setDialogOpen(false);
              handleDelete();
            }}
          ></GConfirmDialog>
        )}

        {defaultDialogOpen === true && (
          <GConfirmDialog
            open={defaultDialogOpen}
            title={strings.s_default_address_title}
            text={strings.s_default_address_subtitle}
            handleClose={() => setDefaultDialogOpen(false)}
            onConfirmClose={() => {
              setDefaultDialogOpen(false);
              handleDefault();
            }}
          ></GConfirmDialog>
        )}
      </ListCard>
    </React.Fragment>
  );
}
