import React, { useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StringsContext } from "../DataFactory/useStrings";
import "../assets/css/slickCarousel.css";
import useWindowSize from "../DataFactory/useWindowSize";
import GItemListView from "./gItemListView";
import { useNavigate } from "react-router-dom";

export default function GItemsSlider(props) {
  const navigate = useNavigate();
  const [stores, setStores] = useState(props.data || []);
  const [strings] = useContext(StringsContext);
  const [display, setDisplay] = useState(true);
  const { width } = useWindowSize();

  var SliderSettings = {
    dots: false,
    infinite: false,
    swipe: false,
    speed: 500,
    slidesToShow:
      props.slidesShow ||
      (width >= 1800
        ? 4.5
        : width > 1500 && width <= 1799
        ? 3.5
        : width > 1279 && width <= 1500
        ? 3.2
        : width > 991 && width <= 1279
        ? 2.3
        : width > 767 && width <= 991
        ? 2.5
        : 1.3),
    slidesToScroll: 1,
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: display ? "block" : "none",
        }}
      >
        <Slider {...SliderSettings}>
          {stores.map((item, index) => {
            return (
              <div key={index}>
                <GItemListView
                  data={item}
                  theme={"column"}
                  mainDivStyle={{
                    margin: "5px 7px",
                  }}
                  settings={props.settings}
                  onSelectItem={() => {
                    navigate(
                      `/${props.settings?.product_display?.toLowerCase()}/${
                        item.id
                      }`,
                      {
                        state: {
                          title: item.title,
                        },
                      }
                    );
                  }}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
